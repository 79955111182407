<template>
  <div v-if="$store.getters.ready">
    <Header />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title border-0 clearfix">
            <div class="page-title-left">
              <h5 class="page-title-heading mt-1 py-2">{{ title }}</h5>
            </div>
          </div>

          <div class="widget-list projectlist">

            <div class="form-group row mb-0">
              <div class="col-md-8 col-lg-9 mb-2">
                <input type="text" v-model.lazy="search" class="form-control" placeholder="Search or filter projects" maxlength="254">
              </div>
              <div class="col-md-4 col-lg-3 mb-2">
                <div class="input-group">
                  <select v-model="sort" class="form-control custom-select">
                    <option value="alphabetical">Alphabetical</option>
                    <option value="reversealphabetical">Reverse alphabetical</option>
                    <option value="newest">Newest</option>
                    <option value="oldest">Oldest</option>
                    <option value="recentlyupdated">Recently updated</option>
                    <option value="leastrecentlyupdated">Least recently updated</option>
                  </select>
                  <div class="input-group-append"><span class="input-group-text"><i class="fal fa-sort-amount-down fa-lg"></i></span></div>
                </div>
              </div>
            </div>

            <div v-if="$auth.profile.verify_email">
              <a href="/profile/verify" class="no-link">
                <div class="widget-holder">
                  <div class="widget-bg">
                    <div class="widget-body text-center">
                      <i class="fal fa-exclamation-triangle fa-lg text-warning pr-2"></i> Your email address in unverified. You must verify your email to join an existing project.
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <AlertCard v-if="!projects.length" icon="fa-folders" title="No projects found" :message="`Add a project by contacting ${this.$config.pretty_name} or your project manager.`" />

            <router-link v-for="project in searchProjects" :key="project.id" :to="`/${project.group.path}/${project.path}`" class="no-link">
              <div class="widget-holder">
                <div class="widget-bg">
                  <div class="widget-body">
                    <div class="row">
                      <div class="col col-md-8">
                        <h5 class="box-title mb-0"><span v-if="title === 'Projects'">{{ project.group.name }} / </span>{{ project.name }}</h5>
                        <span class="text-muted mr-2">{{ project.description }}</span>
                      </div>
                      <div class="col text-left d-none d-md-block">
                        <span v-for="(m, i) in project.members" :key="m.id" class="d-inline-block mt-1" :style="`margin-right: -10px; position: relative; z-index: ${projects.length-i};`"><img :src="m.avatar" class="avatar-sm rounded-circle"></span>
                      </div>
                      <div class="col text-right d-none d-md-block">
                        <div class="box-title mb-0"><span><i class="fal fa-exclamation-circle mr-2"></i>{{ project.issues.open }}</span></div>
                        <div class="text-muted">updated {{ $datefns.fromNow(project.updated_at) }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>

          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Header from '@/components/AccountHeader.vue';
import AlertCard from '@/components/AlertCard.vue';

export default {
  data() {
    return {
      title: 'Projects',
      projects: [],
      search: '',
      sort: localStorage.getItem('projects_sort') ? localStorage.getItem('projects_sort') : 'alphabetical',
    };
  },
  watch: {
    sort() {
      this.sortProjects();
    },
  },
  computed: {
    searchProjects() {
      const list = [];
      const tokens = this.search.split(' ');
      for (let i = 0; i < this.projects.length; i += 1) {
        for (let k = 0; k < tokens.length; k += 1) {
          if (this.projects[i].group.name.toLowerCase().includes(tokens[k].toLowerCase()) || this.projects[i].name.toLowerCase().includes(tokens[k].toLowerCase()) || this.projects[i].description.toLowerCase().includes(tokens[k].toLowerCase())) {
            list.push(this.projects[i]);
            break;
          }
        }
      }
      return list;
    },
  },
  methods: {
    sortProjects() {
      let orderBy;
      let reverseOrder = false;
      switch (this.sort) {
        case 'leastrecentlyupdated':
          orderBy = 'updated_at';
          break;
        case 'recentlyupdated':
          orderBy = 'updated_at';
          reverseOrder = true;
          break;
        case 'oldest':
          orderBy = 'created_at';
          break;
        case 'newest':
          orderBy = 'created_at';
          reverseOrder = true;
          break;
        case 'reversealphabetical':
          orderBy = 'title';
          reverseOrder = true;
          break;
        case 'alphabetical':
        default:
          orderBy = 'title';
      }
      localStorage.setItem('projects_sort', this.sort);

      this.projects.sort((a, b) => {
        if (orderBy === 'title') {
          if (a.group.name.toLowerCase() === b.group.name.toLowerCase()) {
            return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1;
          }
          return (a.group.name.toLowerCase() > b.group.name.toLowerCase()) ? 1 : -1;
        }
        return (a[orderBy] > b[orderBy]) ? 1 : -1;
      });

      if (reverseOrder) {
        this.projects.reverse();
      }
    },
  },
  async mounted() {
    try {
      // check auth for group page
      if (!await this.$auth.isAuthenticated()) {
        this.$store.commit('error', 404);
        return;
      }

      this.projects = (await this.$cache.api.get('/projects')).data;
      // filter group
      if (this.$route.params.group) {
        for (let i = this.projects.length - 1; i >= 0; i -= 1) {
          if (this.projects[i].group.path !== this.$route.params.group) {
            this.projects.splice(i, 1);
          }
        }
        if (!this.projects.length) {
          this.$store.commit('error', 404);
          return;
        }
        this.title = this.projects[0].group.name;
        document.title = `${this.projects[0].group.name} - ${this.$config.pretty_name}`;
      }

      if (this.projects.length === 1) {
        this.$router.push(`/${this.projects[0].group.path}/${this.projects[0].path}`);
        return;
      }

      this.sortProjects();
      this.$store.commit('ready', true);
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  components: {
    Header,
    AlertCard,
  },
};
</script>
